const moment = require("moment-timezone");

const dbTimeFormat = "HH:mm:ss";
const uiTimeFormat = "h:mmA";
const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday",
  "saturday", "sunday"];
const getDayName = timeMoment => timeMoment.format("dddd").toLowerCase();

const outsideClosedRanges = (timeToCheck, closedRangesByDay) =>
{
  const time = moment(timeToCheck);
  const dayToCheck = getDayName(time);
  const closedRanges = closedRangesByDay[dayToCheck];
  if(!closedRanges)
    return null;

  const timeToday = moment(time.format("HH:mm:ss"), "HH:mm:ss");
  return closedRanges.find(closedRange => {
    // We are already checking the correct day of week, so dates don't
    // matter - we shift them all to today's date and keep the times
    const closedFrom = moment(closedRange.from, "HH:mm:ss");
    const closedTo = moment(closedRange.to, "HH:mm:ss");
    return timeToday.isBetween(closedFrom, closedTo, null, "[]");
  }) || null;
}

const getRelevantServiceSchedules = (timeUTC, serviceSchedules) => {
  const relevantServiceSchedules = serviceSchedules.filter(schedule =>
    moment(timeUTC).isBetween(schedule.validFrom, schedule.validThrough, "day", "[]"));

  // Representation invariant:
  // For each department:
  //   Exactly 1 regular schedule must be in force at any given date;
  //   No or 1 holiday schedule can be in force at any given date
  //   All schedules must start at 12AM and end at 11:59:59.999PM for given days

  // TODO: Re-enable check when server-side department filtering is in place
  /*
  if(relevantServiceSchedules.length < 1 || relevantServiceSchedules.length > 2)
    console.warn(`Invariant violation for schedules, found ${relevantServiceSchedules.length} at ${time.toString()}`);
  */

  const holiday = relevantServiceSchedules.find(schedule =>
    schedule.type === "holiday");
  const regular = relevantServiceSchedules.find(schedule =>
    schedule.type === "regular");

  return { regular, holiday };
}
const outsideAnyServiceSchedule = (timeUTC, serviceSchedules, timezone) => {
  const { regular, holiday } = getRelevantServiceSchedules(timeUTC, serviceSchedules);
  const timeInDepartmentTimezone = moment.tz(timeUTC, timezone);
  if(holiday)
    return outsideClosedRanges(timeInDepartmentTimezone, holiday.closed);
  else
    return outsideClosedRanges(timeInDepartmentTimezone, regular.closed);
}

const subtractRanges = (longRanges, shortRanges) => {
  // For convenience
  if(!longRanges.hasOwnProperty("length"))
    longRanges = [longRanges];

  // If done, return an array
  if(shortRanges.length === 0)
    return longRanges.hasOwnProperty("length")
      ? longRanges
      : [longRanges];

  // Result is empty range
  if(longRanges.length === 0)
    return [];

  for(let long in longRanges)
  {
    for(let short in shortRanges)
    {
      if(Number(shortRanges[short]) === 0)
        continue;

      longRanges[long] = longRanges[long].subtract(shortRanges[short])
      if(longRanges[long].length === 0)
      {
        // Subtracted an entire range, remove it from list
        longRanges.splice(long, 1);
        shortRanges.splice(0, short);
        return subtractRanges(longRanges, shortRanges);
      }
      else if(longRanges[long].length === 1)
      {
        // No subtraction made, but .subtract always returns arrays
        longRanges[long] = longRanges[long][0];
      }
      else
      {
        // Successfully subtracted a subrange, flatten and recurse again
        const flat = [].concat(...longRanges);
        shortRanges.splice(0, short);
        return subtractRanges(flat, shortRanges);
      }
    }
  }
  return longRanges;
}

const invertDayRanges = (rangesToInvert) => {
  const day = moment("00:00:00", "HH:mm:ss").range("day");
  const inverseSchedule = subtractRanges(day, rangesToInvert);
  return inverseSchedule;
}

// to UI
// End of day range is 23:59:59.999, but since we only store minutes, need
// to add the secs+milisecs manually
const unserializeTime = (timeIn) => {
  let mom = moment(timeIn, dbTimeFormat);
  const endOfDay = mom.clone().endOf("day");
  if(endOfDay.diff(mom) <= 1000 * 60)
    mom = endOfDay;
  return mom;
}

const unserializeSchedule = (schedule) => {
  if(!schedule || !schedule.closed)
    return schedule;

  Object.entries(schedule.closed).forEach(([day, closedTimes]) => {
    const closedRanges = closedTimes.map(timeObj => {
      return moment.range(
        unserializeTime(timeObj.from),
        unserializeTime(timeObj.to)
      )}
    );

    schedule = {
      ...schedule,
      closed: {
        ...schedule.closed,
        [day]: closedRanges
      },
      open: {
        ...(schedule.open || {}),
        [day]: invertDayRanges(closedRanges)
      }
    }
  });

  schedule.validFrom = moment(schedule.validFrom);
  schedule.validThrough = moment(schedule.validThrough);
  
  return schedule;
}

// to DB
const serializeTime = (momentTime) => {
  return momentTime.format(dbTimeFormat);
}
const serializeSchedule = (schedule) => {
  Object.keys(schedule.open).forEach(day => {
    const openTimes = schedule.open[day];
    const closedTimes = invertDayRanges(openTimes).map(range => ({
      from: serializeTime(range.start),
      to: serializeTime(range.end)
    }));
    schedule.closed[day] = closedTimes;
    schedule.validThrough = schedule.validThrough.endOf("day");
  })
  return schedule;
}

module.exports = {
  daysOfWeek,
  getDayName,
  uiTimeFormat,
  dbTimeFormat,
  serializeTime,
  unserializeTime,
  serializeSchedule,
  unserializeSchedule,
  getRelevantServiceSchedules,
  outsideClosedRanges,
  outsideAnyServiceSchedule
};
