import React from 'react';

import { connect } from 'react-redux';
import { loadUser, enterQuery } from '../../actions';
import { getBarcodeResource, withoutDeleted } from "../../selectors";
import API from "../../api";

import Icon from '../Icon.jsx';
import { Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, FormControl, Input } 
  from "reactstrap";

import Select from 'react-select';
import classNames from "classnames";

import styles from "./index.scss";
import 'react-select/dist/react-select.css';

const generateBookingLink = bookedResource => 
  `/app/${bookedResource.Booking.status == "reserved" ? "activate" : "return"}/${bookedResource.Booking.Keeper.id}/${bookedResource.Booking.id}/${bookedResource.id}`;

const KEY_ENTER = 13;

const getUsers = input => 
  API.get("users", {nameSearch: input})
    .then(json => {
      json = json.map(user => ({
        value: user.id,
        label: user.name + ` (${user.email.substr(0, user.email.indexOf("@"))})`
      }));
      return { options: json };
    });

const getResources = input => 
  API.get("resources", { nameSearch: input })
    .then(json => {
      json = json.map(resource => ({
        value: resource.id,
        label: `${resource.name} (${resource.Category.name})`
      }));
      return {options: json};
    });

const getBookedResources = input => 
  API.get("searchBookedResources", { name: input })
    .then(json => {
      json = json.map(bookedResource => ({
        value: generateBookingLink(bookedResource),
        label: <span>
          <strong>{bookedResource.Resource.name}</strong>
          {": "}
          {bookedResource.Booking.Keeper.name}
          {" "}
          <small>({bookedResource.Booking.status})</small>
        </span>
      }));
      return {options: json};
    });

const getBarcodeResourceOption = (DepartmentId, resources, input) => {
  let barcodeOptions = [];
  const barcodeResource = resources.filter(resource => resource.DepartmentId === DepartmentId)
    .find(resource => resource.barcode == input);
  if(barcodeResource)
  {
    barcodeOptions = [
      {label: "Barcode resource", disabled: true},                                       
      {label: `${barcodeResource.name} (${barcodeResource.barcode})`, value: "barcode"}, 
    ];
  }
  return barcodeOptions;
}

const getOptions = (searchUsers, searchResources, searchBookedResources, resources, DepartmentId) => input =>
{
  const barcodeOptions = getBarcodeResourceOption(DepartmentId, resources, input);

  let dataGettersPromise = Promise.resolve([{options: []}, {options: []}, {options: []}]);
  if(input.length > 2 && (searchUsers || searchResources || searchBookedResources))
  {
    const usersPromise = searchUsers ? 
      getUsers(input) : Promise.resolve({ options: [] });
    const resourcesPromise = searchResources ?
      getResources(input) : Promise.resolve({ options: [] });
    const bookedResourcesPromise = searchBookedResources ?
      getBookedResources(input) : Promise.resolve({ options: [] });

    dataGettersPromise = Promise.all([usersPromise, resourcesPromise, bookedResourcesPromise]);
  }

  return dataGettersPromise.then(([users, resources, bookedResources]) => {

    const usersLabel = (users.options && users.options.length > 0) ? "Users" : "No users found";
    const resourcesLabel = (resources.options && resources.options.length > 0) ? "Resources" : "No resources found";
    const bookedResourcesLabel = (bookedResources.options && bookedResources.options.length > 0) ?
      "Booked resources" : "No booked resources found";

    let options = [...barcodeOptions];
    if (users.options && searchUsers) {
      options.push({ label: usersLabel, disabled: true });
      options = options.concat(users.options);
    }
    if (resources.options && searchResources) {
      options.push({ label: resourcesLabel, disabled: true });
      options = options.concat(resources.options);
    }
    if (bookedResources.options && searchBookedResources) {
      options.push({ label: bookedResourcesLabel, disabled: true });
      options = options.concat(bookedResources.options);
    }

    return { options };
  });
}

class OmniboxPresentational extends React.Component {
  state = {
    options: []
  }

  componentWillUnmount(){
    clearTimeout(this.timeoutHandle);
  }

  queryEntered = input => {
    const { searchUsers=false, searchResources=false, searchBookedResources=false, resources } = this.props;
    clearTimeout(this.timeoutHandle);
    this.timeoutHandle = setTimeout(() => {
      getOptions(searchUsers, searchResources, searchBookedResources, resources, this.props.DepartmentId)(input)
        .then(options => this.setState(options));
      this.props.queryEntered(input);
    }, 250);
  }

  render() {
    const {optionSelected, queryEntered, onEnterDown, value,
      searchUsers, searchResources, searchBookedResources, placeholder="Search"} = this.props;

    const options = this.state.options;
    return (
      <div className={classNames(styles.container, "omnibox")}>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <Icon icon="search" color="dark" />
            </InputGroupText>
          </InputGroupAddon>
          <Select
            className={styles.omnibox}
            name="search"
            options={options}
            noResultsText={false}
            cache={false}
            clearable={true}
            loadOptions={getOptions}
            value={value}
            onChange={optionSelected} 
            onInputChange={this.queryEntered}
            onInputKeyDown={(event) => (event.keyCode == KEY_ENTER && onEnterDown) ? onEnterDown(event) : undefined}
            onBlurResetsInput={false}
            onCloseResetsInput={false}
            filterOption={() => true}
            placeholder={placeholder} />
        </InputGroup>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    resources: withoutDeleted(state.resources),
    DepartmentId: state.selectedDepartment
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    queryEntered: (input) => {
      dispatch(enterQuery(input));
    }
  }
}

const Omnibox = connect(
  mapStateToProps,
  mapDispatchToProps
)(OmniboxPresentational)


export default Omnibox;
